/* Project Description */
.project-description {
  background-color: #f9f9f9;
  padding: 40px 0;
  margin-bottom: 30px;
}

.project-description .container {
  max-width: 800px;
  margin: 0 auto;
}

.project-description .section-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.project-description p {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #555;
  text-indent: 2em; /* Indent the first line of each paragraph */
}

.project-description strong {
  color: #007bff; /* Highlighted text in project description */
}
