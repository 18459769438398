.instructor-card-wrapper {
  display: flex;
  justify-content: center; /* Centers the card horizontally */
  margin-top: 20px;
}

.instructor-card {
  width: 300px; /* Set the width of the card */
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
  border-radius: 10px; /* Round the card's corners */
  text-align: center;
}

.instructor-image {
  width: 150px;
  height: 150px;
  border-radius: 50%; /* Makes the image circular */
  object-fit: cover; /* Ensures the image fills the circular container */
  margin-bottom: 15px;
  border: 2px solid #333; /* Optional border around the image */
}

.instructor-card h3 {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}
