/* Footer */
.footer {
  background-color: #343a40;
  color: #fff;
  text-align: center;
  padding: 1rem 0;
  margin-top: 2rem;
}

.footer p {
  font-size: 0.9rem;
}
