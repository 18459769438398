/* Header */
.header {
  /* Make a blue background-color for header */
  background-color: #282c34;
  color: #fff;
  padding: 20px 0;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.logo-title {
  display: flex;
  align-items: center;
}

.cherry-logo {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.title-container {
  display: flex;
  flex-direction: column;
}

.project-title {
  font-size: 2rem;
  font-weight: bold;
}

.pronunciation {
  font-size: 1rem;
  color: #ccc;
}

/* Menu Bar */
.navbar {
  display: flex;
}

.menu-list {
  display: flex;
  list-style-type: none;
  margin: 0;
}

.menu-list li {
  margin-left: 20px;
}

.menu-list a {
  color: #fff;
  text-decoration: none;
  font-size: 1.1rem;
  padding: 10px 15px;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 5px;
}

.menu-list a:hover {
  background-color: #61dafb; /* Brighter blue for hover effect */
  color: #282c34;
  cursor: pointer;
}

/* Mobile Menu Button */
.mobile-menu-btn {
  display: none;
  background: none;
  color: #fff;
  font-size: 2rem;
  border: none;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #282c34;
    width: 100%;
    padding: 20px 0;
  }

  .navbar.open {
    display: flex;
  }

  .menu-list {
    flex-direction: column;
    text-align: center;
  }

  .menu-list li {
    margin: 15px 0;
  }

  .mobile-menu-btn {
    display: block;
  }

  .header-content {
    flex-wrap: wrap;
  }

  .logo-title {
    margin-bottom: 20px;
  }
}

.logout-button {
  background-color: #dc3545;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-left: auto;
}

.logout-button:hover {
  background-color: #c82333;
}
