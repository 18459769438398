/* Center the View Meeting Logs link */
.view-logs-container {
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
}

.view-logs-link {
  display: inline-block;
  margin: 20px 0;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
}

.view-logs-link:hover {
  background-color: #0056b3;
}

.add-meeting-button {
  display: inline-block;
  margin: 20px 0;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
}

.add-meeting-button:hover {
  background-color: #218838;
  cursor: pointer;
}

.meeting-image {
  max-height: 300px;
  width: 100%;
  object-fit: contain;
  border-radius: 8px;
  background-color: #f8f9fa;
}


/* Image Styling */
.meeting-image {
  width: 100%;
  height: auto;
  object-fit: contain; /* Ensures the image fits within the container without cropping */
}

