/* GitHub Section */
.github-section {
  padding: 2rem 0;
  background-color: #fff;
}

.github-list {
  list-style: none;
  text-align: center;
}

.github-list li {
  margin-bottom: 1rem;
}

.github-list a {
  text-decoration: none;
  color: #007bff;
  font-size: 1.2rem;
}

.github-list a:hover {
  text-decoration: underline;
}
