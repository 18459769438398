.add-meeting-log {
  background-color: #f9f9f9;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  max-width: 500px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-meeting-log h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.add-meeting-log form {
  display: flex;
  flex-direction: column;
}

.add-meeting-log input,
.add-meeting-log textarea {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.add-meeting-log textarea {
  resize: vertical;
  height: 100px;
}

.add-meeting-log input[type="file"] {
  padding: 5px;
  font-size: 16px;
}

.add-meeting-log button {
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-meeting-log button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.add-meeting-log button:hover:enabled {
  background-color: #0056b3;
}
