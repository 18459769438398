/* CSS for Login page */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height to vertically center the login card */
  background-color: #f4f4f4;
}

.login-card {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.login-card h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.login-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.login-input:focus {
  outline: none;
  border-color: #007bff;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #0056b3;
}

.login-error {
  color: red;
  margin-bottom: 15px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .login-card {
    padding: 20px;
  }

  .login-card h2 {
    font-size: 20px;
  }

  .login-input {
    padding: 8px;
    font-size: 14px;
  }

  .login-button {
    padding: 8px;
    font-size: 14px;
  }
}
