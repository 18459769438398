/* Reports Section Styles */
.reports-section {
  padding: 2rem 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  max-width: 800px;
  margin: 2rem auto;
}

/* Section Title */
.section-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #333;
  font-weight: 600;
}

/* Report List */
.report-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.report-list li {
  background-color: #fff;
  background-color: #007bff;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.2s, transform 0.2s;
}

/* Hover Effect for List Items */
.report-list li:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-3px);
}

/* Report Links */
.report-list a {
  text-decoration: none;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  transition: color 0.2s;
}

/* Hover Effect for Links */
.report-list a:hover {
  color: #cce5ff
}

/* Add File Section */
.add-file-section {
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 6px;
  margin-top: 2rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

/* Add File Input and Button */
.add-file-section input[type="text"],
.add-file-section input[type="file"] {
  width: 100%;
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.add-file-section button {
  width: 100%;
  padding: 0.8rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
}

.add-file-section button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}
