/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  background-color: #f4f4f4;
  color: #333;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: auto;
}

.logbook-link {
  display: block;
  margin-bottom: 20px;
  color: #007bff;
  font-size: 18px;
  text-decoration: underline;
}

.logbook-link:hover {
  color: #0056b3;
}

.github-button-wrapper {
  text-align: center; /* Center the button */
  margin-top: 20px;
}

.github-button {
  display: inline-flex;
  align-items: center;
  background-color: #333;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.github-button:hover {
  background-color: #444;
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.github-icon {
  margin-right: 10px;
}

.github-text {
  font-weight: bold;
}

.view-logs-link {
  display: inline-block;
  margin: 20px 0;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
}

.view-logs-link:hover {
  background-color: #0056b3;
}

.meetings-section {
  padding: 20px;
  background-color: #f9f9f9;
}

.section-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.logbook-link {
  display: block;
  text-align: center;
  margin-bottom: 20px;
  font-size: 16px;
  color: #007bff;
  text-decoration: none;
}

.logbook-link:hover {
  text-decoration: underline;
}

.meeting-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.meeting-card {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.meeting-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 15px;
}

.placeholder-image {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  color: #888;
  border-radius: 5px;
  margin-bottom: 15px;
  font-size: 16px;
}

.login-prompt {
  margin: 20px 0;
  text-align: center;
}

.login-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
}

.login-button:hover {
  background-color: #0056b3;
}
