/* Team Section */
.team-section {
  padding: 2rem 0;
  background-color: #fff;
}

.team-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.team-member {
  background-color: #f8f9fa;
  padding: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
  flex-basis: 30%;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.team-member h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.team-member p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.team-member a {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}

.team-member a:hover {
  text-decoration: underline;
}

.team-member-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #333; /* Optional border around the image */
  margin-bottom: 15px; /* Adds spacing below the image */
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(200px, 1fr)
  ); /* Responsive grid */
  gap: 20px; /* Spacing between team members */
}

.team-member {
  text-align: center; /* Centers the content within each team member box */
  margin-bottom: 20px;
}

.icon-link {
  margin-right: 10px;
  color: #333; /* Default icon color */
  transition: color 0.3s ease;
}

.icon-link:hover {
  color: #0077b5; /* Change color on hover */
}
